import './blog.css';
import { useState, useEffect } from 'react';
import BlogCard from './BlogCard';
// import blogData from '../../data/blogData.js';
import { db } from '../../config/firebase';
import { getDocs, collection } from 'firebase/firestore';


const BlogList = () => {
    const [blogList, setBlogList] = useState([]);
    const blogCollectionRef = collection(db, 'blogpost');

    useEffect(()=> {
        const getBlogList = async () => {
            try {
                const data = await getDocs(blogCollectionRef);
                const filteredData = data.docs.map((doc)=> ({...doc.data(), id: doc.id }))
                setBlogList(filteredData)
                console.log(filteredData)
            } catch (err) {
                console.error(err)
            }
        };
        getBlogList()
    
    }, [])


    return (
        <div className='blog-group'>
            { blogList.map((blog) => (
                <BlogCard key = {blog.id} {...blog} />
            )) }
        </div>
    );
}
 
export default BlogList;