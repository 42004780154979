import { useEffect, useState } from 'react';
import './blog.css';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { generateSlug } from "../../utils.js"

const BlogDetail = () => {
    const { id, slug } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const fetchBlogDetail = async () => {
            try {
                const blogDocRef = doc(db, 'blogpost', id);
                const blogDocSnapshot = await getDoc(blogDocRef);

                if (blogDocSnapshot.exists()) {
                    setBlog({ ...blogDocSnapshot.data(), id: blogDocSnapshot.id });
                } else {
                    console.log('Blog not found');
                }
            } catch (error) {
                console.error('Error fetching blog detail:', error);
            }
        };

        fetchBlogDetail();
    }, [id]);

    if (!blog || generateSlug(blog.title) !== slug) {
        return <div>Loading...</div>;
    }

    const blogDate = blog.created.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    const blogTime = blog.created.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return (
   
        <section className='container'>
            <div className='blog-detail-container'>
            <h2 className='blog-detail-title'>{blog.title}</h2>
                <img src={blog.imageUrl} alt='Blog Cover' className='img-fluid' />
                <div className='blog-detail-timeDate'>by <span className='blog-detail-author'>{blog.author}</span> on: {blogDate} - {blogTime} </div>
                <div className='blog-detail-content'>{blog.content}</div>
                <div className='blog-detail-category'>Category: {blog.category}</div>
            </div>
        </section>
    );
};

export default BlogDetail;