import BlogList from './BlogList';
import './blog.css';

const Blog = () => {
    return ( 
        <section className="blog-wrapper container">
            <h2 className='text-center'>Welcome to AmazingDojo Blog</h2>
            <div className='blog-container'>
                <BlogList />
            </div>
        </section>
     );
}
 
export default Blog;