import { Link } from 'react-router-dom';
import './blog.css'
import { generateSlug } from '../../utils';


const BlogCard = (props) => {
    const { id, title, author, created, category, imageUrl } = props;
    const createdDate = created.toDate();

    // Format date and time
    const formattedDate = createdDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  
    const formattedTime = createdDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });



    return ( 
        <Link to={`/blog/${id}/${generateSlug(title)}`} className='blog-item'>
            <div className='blog-tags'> { category }</div>
            <div className='blog-image'>
                <img src={ imageUrl } alt='' className='img-fluid' />
            </div>
            <div className='blog-title'>
                {title}
            </div>
            <div className='blog-author'>By { author } - on: {formattedDate} at {formattedTime} </div>
        </Link>
     );
}
 
export default BlogCard;