import './notfound.css';
import { Link } from 'react-router-dom';

const NotFound = () => {

    const badRequest = 'https://img.freepik.com/free-vector/400-error-bad-request-concept-illustration_114360-1921.jpg?w=1380&t=st=1702443378~exp=1702443978~hmac=abd577b5fd7d2b244c54c47c8a87824b970e11b5a7f3a7ac93e7e8cbbcb5d924'

    return ( 
    <div className='container not-found'>
    <img src={badRequest} alt='page not found' className='img-fluid' />
    <h2>Page Not Found</h2>
    <p>
      The page you are looking for does not exist. <Link to="/">Go back to the home page.</Link>
    </p>
    </div>
    );
}
 
export default NotFound;