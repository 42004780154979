import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAGUxURziMgNuxg7mqvqoIoKnm5-pF5C9s",
  authDomain: "fir-9-dojo-f4339.firebaseapp.com",
  projectId: "fir-9-dojo-f4339",
  storageBucket: "fir-9-dojo-f4339.appspot.com",
  messagingSenderId: "1073757416488",
  appId: "1:1073757416488:web:552d04b11792d80da9591d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);